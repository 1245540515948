import { useState } from 'react';
import UserActions from "./UserActions"
import CreateUserForm from './CreateUserForm';

function useUserCreateState() {
  const [item, setItem] = useState(null);
  const [result, setResult] = useState(null)
  
  
  const handleStatusChange  = (data) => {
    
    setResult(data)
  }


  const createNewUser = (params) => {
    setItem(new CreateUserForm(params))
  }


  const updateHandler = (name, value) => {
    setItem(item.set(name,value))
  }

  const create = () => {
   UserActions.create(item, handleStatusChange )
  }

  return {user:item, createUser:create, createNewUser:createNewUser, updateUser:updateHandler, create_result:result};
}

export default useUserCreateState