import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import config from 'react-global-configuration';
import configuration from './config.js';
import RegisterApp from "./register/RegisterApp"
import RegisterSucessApp from "./register/SuccessApp.js"

config.set(configuration);

class App extends React.Component{

render(){
return(
    <div>
      <Routes>
        <Route  path='/register/student/success' element={<RegisterSucessApp />} />
        <Route  path='/register/student/:year' element={<RegisterApp />} />
      </Routes>
    </div>
)}}

const container = document.getElementById('root')
const root = createRoot(container)

root.render((<BrowserRouter ><App /></BrowserRouter>))

