
import { Record } from 'immutable'
const CreateUserForm = Record({
  name_last:null,
  name_first:null,
  name_maiden:null,
  kana_last:null,
  kana_first:null,
  kana_maiden:null,
  mail1:null,
  category_number:null,
  post_code:null,
  address1:null,
  address2:null,
  address3:null,
  company_name:null,
  juniour_school_name:null,
  university_name:null,
  club_name:null,
  is_high_school:null,
  is_student:null,
  password:null,
  comment:null
});
export default CreateUserForm;
