import React, {  } from 'react'



function NewsList(props:PropsValue) {
  

  return(
    <div className="news form" >
    <h2>お知らせ</h2>
    <ul>
      <li>
        3/19日に番外交流会が開催されます。<a href="http://bangai.kiyoko-kanto.jp/" target='blank'>詳しくはこちら</a>
      </li>
    </ul>
  </div>
  )
}

export default NewsList
