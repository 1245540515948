import React, { useState,  useEffect } from 'react'
import { useParams } from 'react-router-dom';
import ValueItem from '../form/ValueItem';
import useUserCreateState from '../user/useUserCreateState';
import {Oval} from 'react-loader-spinner'
import NewsList from '../news/NewsList';


function RegisterApp(props:PropsValue) {

  const {year} = useParams()
  const [item ,setItem] = useState(null)
  const [message, setMessage] = useState(null)
  const [is_btn, setIsBtn] = useState(true)
  const {user, createNewUser, createUser ,updateUser, create_result} = useUserCreateState()

  useEffect(() => {
    createNewUser({
      category_number: ( year - 1948 ),
      is_high_school :0, 
      is_student: 1
    })
    return function cleanup() { 

    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    
    setIsBtn(true)
    if(create_result && create_result.error){
      setMessage("エラーが発生しました。すでに登録済みのメールの可能性があります。ご確認ください。")
      return false
    }
    if(create_result && !create_result.error && create_result.id){
      window.location.href = "/register/student/success"
    }

   
    
  },[create_result]);

  useEffect(() => {
    setItem(user)
  },[user]);


  function changeHandler(name,value){
    updateUser(name, value)
  }

  function clickHander() {
    if(!item.name_last || item.name_last === "" ||  !item.name_first || item.name_first === ""   ){
      setMessage("氏名を入力してください。")
      return false
    }

    if(!item.kana_last || item.kana_last === "" ||  !item.kana_first || item.kana_first === ""   ){
      setMessage("フリガナを入力してください。")
      return false
    }

    if(!item.mail1 || item.mail1 === ""   ){
      setMessage("メールアドレスを入力してください。")
      return false
    }

    if(!item.mail1.match(/.+@.+\..+/)  ){
      setMessage("メールアドレスが不正です。")
      return false
    }

    setMessage(null)
    setIsBtn(false)
    createUser()
  }

  return(
    <div>
      <header>
        <img src="https://kiyoko-kanto.jp/wp-content/themes/child/img/menu_logo.png" alt="logo" />
      </header>
      <main className="full-back">
      
        <div className="form">
        <h1>清水東高卒業生に関東地区同窓会会員登録のお願い</h1>
        <p>卒業おめでとう。関東同窓会の扉はいつでも開いています。<br/>君たちとのつながりをご連絡ください。関東在住者でなくても登録できます。</p>

          <div className="item">
            <ValueItem className="half" type="text" placeholder="姓" label="姓" name="name_last" changeHandler={changeHandler}  defaultValue={item ? item.name_last : null }/>
            <ValueItem className="half" type="text" placeholder="名" label="名" name="name_first" changeHandler={changeHandler}  defaultValue={item ? item.name_first : null } />
          </div>
          
          <div className="item">
            <ValueItem className="half" type="text" placeholder="フリガナ(姓)" label="フリガナ(姓)" name="kana_last" changeHandler={changeHandler}  defaultValue={item ? item.kana_last : null }/>
            <ValueItem className="half" type="text" placeholder="フリガナ(名)" label="フリガナ(名)" name="kana_first" changeHandler={changeHandler}  defaultValue={item ? item.kana_first : null } />
          </div>
          
          <div className="item">
            <ValueItem type="text" placeholder="メールアドレス" label="メールアドレス" name="mail1" changeHandler={changeHandler}  defaultValue={item ? item.mail1 : null } />
          </div>
          
          <div className="item">
            <ValueItem type="password" placeholder="パスワード" label="パスワード" name="password" changeHandler={changeHandler}   defaultValue={item ? item.password : null }/>
          </div>
          
          <div className="item">
            <ValueItem type="textarea" placeholder="コメント" label="コメント" name="comment" multi="true" changeHandler={changeHandler}  defaultValue={item ? item.comment : null } />
          </div >
          <div className="notice">{message}</div>
          <div className="item">
            {is_btn ?  <input type="button" className="big-btn" onClick={clickHander} value="登 録"/> : <div className="center"><Oval color="#3aab5e" height={20} width={20} strokeWidth={4}/></div> }
          
          </div>

          <div className="link"><a href="https://kiyoko-kanto.jp/">[清水東関東同窓会ページへ]</a></div>
        </div>
        <NewsList />
      
      </main>
    </div>
  )
}

export default RegisterApp
