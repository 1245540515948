import React, {  } from 'react'
import NewsList from '../news/NewsList';

function SuccessApp(props:PropsValue) {


  

  return(
    <div>
      <header>
        <img src="https://kiyoko-kanto.jp/wp-content/themes/child/img/menu_logo.png" alt="logo" />
      </header>
    <main className="full-back">
      
      <div className="form">
      <h1>関東地区同窓会会員登録</h1>
        
        <div>会員登録が完了いたしました。ご登録いただいたアドレスに、同窓会等のご連絡を差し上げます。</div>

        <div className="link"><a href="https://kiyoko-kanto.jp/">[清水東関東同窓会ページへ]</a></div>
      </div>

      <NewsList />
     </main>
     </div>
  )
}

export default SuccessApp
